<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>My Shifts</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">My Shifts</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>

             <b-row>
                <b-col lg="3" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Year</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="year"
                                :options="years"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>
                 <b-col lg="2" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Month</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="month"
                                :options="months"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>

                <b-col lg="2" class="mb-1rem">
                    <button class="btn btn-primary" @click="onSelection()">
                        Filter
                    </button>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                @filtered="onFiltered"
                responsive
            >  
            </b-table>

        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "",
                order: "",
                page: 1,
                per_page: 10,
                shift_id:""
            },
            years: [],
            year: new Date().getFullYear(),
            total_years:2,
            success: false,
            updated: false,
            deleted: "",
            api_error: "",
            sortBy: "emp_id",
            sortDesc: false,
            fields: [
                { label:"Shift",key: "shift.name", sortable: false },
                { key: "from_date", sortable: false,
                    formatter: value => {
                        return this.dateformat(value);
                    }

                },
                { key: "to_date", sortable: false,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                },
                { label:"Week Offs" ,key: "week_off", sortable: false,
                    formatter: value => {
                        return this.week_offs(value);
                    }
                }
            ],
            shifts:[
                { value:"" ,text:"Select Shift" },
            ],
            items: null,
            totalRows: 1,
            lastPage: 1,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message:"",
            month:"01",
            months: [
                { value: "01", text: "January" },
                { value: "02", text: "February" },
                { value: "03", text: "March" },
                { value: "04", text: "April" },
                { value: "05", text: "May" },
                { value: "06", text: "June" },
                { value: "07", text: "July" },
                { value: "08", text: "August" },
                { value: "09", text: "September" },
                { value: "10", text: "October" },
                { value: "11", text: "November" },
                { value: "12", text: "December" }
            ]
        };
    },
    mounted() {
        this.access = this.hasAnyPermission(['CAN_VIEW_MY_SHIFTS']);
        this.getYears();
        this.getMyShifts(this.getDefaultFilters());
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },

        onFiltered() {
            this.urlBody.query = this.filter;
            this.urlBody.page = 1;
            let urlBody = this.deleteEmptyKeys(this.urlBody);
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: urlBody
                }).catch(()=>{})
            this.getMyShifts();
        },

        getDefaultFilters() {
            let api_params = {
                year: this.year,
                month: this.month,
                order:"asc",
                sort_by: "date",
            };
            return api_params;
        },

        getYears() {
            const currentYear = (new Date()).getFullYear();
            this.years.push({
                value: currentYear-1, text:currentYear-1
            })
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear+index, text:currentYear+index
                })
            }
        },


        getMyShifts(params){
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = params;
            this.axios
                .get(this.$api.my_shifts, query)
                .then(response => {
                    const result = response.data.data;
                    this.items = result;
                    this.urlBody.page = result.current_page;
                    this.urlBody.per_page = result.per_page;
                    this.lastPage = result.last_page;
                    this.totalRows = result.total;
                    this.deleted = "";
                    this.api_error = "";
                    this.updateProgressBar(true);
                    // this.$router
                    //     .push({
                    //         path: this.$route.params[0],
                    //         query: this.deleteEmptyKeys(params)
                    //     }).catch(()=>{})
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },
        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            urlBody.page = 1;
            this.api_error = "";
            this.getMyShifts(urlBody);
        },
        checkPermit: function(permission) {
            return this.hasPermission(permission);
        },
        pagination: function(pageNumber) {
            var newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.page = pageNumber;
            this.api_error = "";
            this.getMyShifts(newQuery);
        },

        changePageCount: function() {
            let newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.per_page = this.urlBody.per_page;
            this.deleted = "";
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: newQuery
                })
                .catch(err => {
                    console.error(err);
                });
            this.getEmployeeShifts(newQuery);
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },

        week_offs(value){
            let data= JSON.parse(value);
            return data.join(', ');
        },

        onSelection() {
            this.getMyShifts(this.getDefaultFilters());
            
        },

    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>